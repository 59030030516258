window.etoile = (window.etoile || {});

etoile.Search = (function Search($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $form = $('.js-search-form');
  var $content = $('.js-cards-content');
  var $loading = $('.js-loading');

  var $queryField = $('.js-search-query', $form);
  var $categoryField = $('.js-search-category', $form);
  var $dateField = $('.js-search-date', $form);
  var $locationField = $('.js-search-location', $form);

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize events
    $form.on('submit', function(e) {
      e.preventDefault();
      searchResults();
    });

    $categoryField.on('change', function() {
      $queryField.val('');
      $(this).next().addClass('checked');
      $categoryField.not($(this)).next().removeClass('checked');
      $form.submit();
    });

    $dateField.on('change', function() {
      $form.submit();
    });

    $locationField.on('change', function() {
      $form.submit();
    });

    $content.on('click', '.js-show-more', function(e) {
      e.preventDefault();
      moreResults($(this));

    });

    // Return success
    return true;

  };

  /**
   * Search results.
   * @private
   */
  var searchResults = function() {

    $content.fadeOut(function() {

      $content.empty();

      $loading.css('display','block');

      var url = $form.attr('action');
      var data = $form.serialize();

      $.ajax({ type: 'GET', url: url, data: data, cache: false }).done(function(response) {

        $loading.css('display','none');
        $content.html(response);
        $content.fadeIn();
        $('.js-cards', $content).matchHeight({ byRow: false });

      });

    });

  };

  /**
   * Load more results.
   * @private
   */
  var moreResults = function($button) {

    var url = $button.attr('href');
    var month = $('.js-cards').last().data('month');
    console.log(month);
    var data = $form.serialize() + '&month=' + month;

    $button.addClass('loading');

    $.ajax({ type: 'GET', url: url, data: data, cache: false }).done(function(response) {

      $button.remove();
      $content.append(response);
      $('.js-cards, .show-more-wrapper', $content).matchHeight({ byRow: false });
      console.log(data);

    });

  };

  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
