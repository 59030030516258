window.etoile = (window.etoile || {})

etoile.Newsletter = (function Newsletter($) {

  'use strict';
  /**
   * jQuery elements.
   * @private
   */
  var $form = $('.newsletter-form');

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $form.on('submit', function(event) {
      event.preventDefault();
      submitNewsletter($(this));
    });

    // return success
    return true;

  };

    /**
   * Submit contact forms.
   * @private
   */
  var submitNewsletter = function($form) {

    var data;
    var $button = $('input[type="submit"]', $form);

    $('[action-error-text]').removeClass('is-active');
    $('[action-error-text]').remove('.error-msg');
    $('[action-success-text]').removeClass('is-active');

    if ( !$form.parsley().validate() ) {
      $('input[name="fields[email]"').addClass('parsley-error');
      return false;
    }

    // Reset fields errors.
    $('input, textarea, select', $form).removeClass('error');

    // Serialize data and append csrf token.
    data = new FormData($form[0]);
    data.append('etoile_csrf', etoile.App.getConfig('csrf'));

    // Submit the form through ajax post.
      var request = $.ajax({
        url: '/mailing/subscribeNewsletter',
        type: 'POST',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
      });

      request.done(function(response){
        var r = $.parseJSON(response);

        if(r.status && r.status === 'success') {
          console.log('in-success');
          $('[action-error-text]').removeClass('is-active');
          $('[action-success-text]').addClass('is-active');
          $('[action-success-text]').text(r.message)

        }
        else if (r.status && r.status == 'error') {
          console.log('in-error');
          $('[action-success-text]').removeClass('is-active');
          $('[action-error-text]').addClass('is-active');
          $('[action-error-text]').append( "p" ).addClass("error-msg").text(r.message);
        }
      });
  };

  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
