window.etoile = (window.etoile || {});

/**
 * Utilities for interacting with the application.
 * @class App
 * @static
 */
etoile.Carousel = (function Carousel($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  var $etoile = $('.js-upcoming-carousel, .js-related-carousel');
  var $club = $('.js-club-carousel');
  var $promo = $('.js-promo-carousel');
  var $featured = $('.js-featured');
  var viewport = etoile.App.checkViewport();

  /**
   * Initializes the class.
   * @public
   */
  var init = function (options) {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    upcomingCarousel();
    clubCarousel();
    featuredCarousel();
    initSlides(viewport);


    $(window).on('resize', function () {

      viewport = etoile.App.checkViewport();

      initSlides(viewport);
    });



    // Return success
    return true;

  };

  var upcomingCarousel = function(){

    $etoile.slick({
      infinite: false,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: $('.js-upcoming-prev'),
      nextArrow: $('.js-upcoming-next'),
      responsive: [
        {
          breakpoint: 1791,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 1457,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1123,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  };

  var clubCarousel = function(){

    $club.slick({
      infinite: false,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: $('.js-club-prev'),
      nextArrow: $('.js-club-next'),
      responsive: [
        {
          breakpoint: 1791,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 1457,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1123,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  };

  var promoCarousel = function(){

    init.called = true;


    $promo.slick({
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $('.js-promo-prev'),
      nextArrow: $('.js-promo-next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  };

  var featuredCarousel = function(){

    $featured.slick({
      infinite: true,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $('.js-featured-prev'),
      nextArrow: $('.js-featured-next'),
    });

  };


  var initSlides = function(e){

      if (e != 'large') {

        promoCarousel();

        $featured.on('afterChange',function(event, slide, index){
          var height = $("[data-slick-index='"+index+"']").height();
          $('.hero-nav').css('top',height);
        })

      }else{

        if(init.called)
        $promo.slick('unslick');

      }
  };


  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
  };

})(jQuery);
