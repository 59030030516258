window.etoile = (window.etoile || {})

etoile.Map = (function Map($) {

  'use strict';
  /**
   * jQuery elements.
   * @private
   */
  const $locations = [].slice.call(document.querySelectorAll('.js-location'));

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    initMap();

    // return success
    return true;

  };


    /**
   * Initialize Google Maps instances.
   * @public
   */
  var initMap = () => {

    // The map, centered at Uluru
    if (document.querySelector('[data-map]')) {
      var map = new google.maps.Map(
      document.querySelector('[data-map]'), {
        zoom: 8,
        center: {lat: 45.581034, lng: -73.771768},
      });

      var marker, i;
      var customSizeImage = {
          url: '/assets/images/map-cursor.svg',
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(40, 45)
        };

      for (i = 0; i < $locations.length; i++) {
        marker = new google.maps.Marker({
          icon: customSizeImage,
          position: new google.maps.LatLng($locations[i].dataset.lat, $locations[i].dataset.lng),
          map: map
        });

        google.maps.event.addListener(marker, "click", (function(marker) {
          return function(evt) {
            var url = 'https://www.google.com/maps?daddr=' + marker.getPosition().lat() + ',' + marker.getPosition().lng();
            var win = window.open(url, '_blank');
            win.focus();
          }
        })(marker));
      }
    }

    // The marker, positioned at Uluru
    // var marker = new google.maps.Marker({position: montreal, map: map, icon: '/img/site/icon-marker.png'});
    // var marker = new google.maps.Marker({position: longueuil, map: map, icon: '/img/site/icon-marker.png'});
  };


  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
